import { Formik, getIn } from "formik";
import React, { useContext, useState, useEffect } from "react";
import { Form, InputOnChangeData, Message, SemanticWIDTHS } from "semantic-ui-react";
import { AppCheckBoxRadio, AppDate, AppDropdown, AppRadioGroup, AppTextbox, MultiCheckBox, IOption, WhichRoutineDoseRadio } from "./AppFormInputs";
import { AppLabel } from "./AppLabel";
import AppContext from "../../contexts/AppContext";
import moment from "moment";
import { getVaccinationInfoByLocation } from './../../services/VaccineInfoByLocationfromSF'
import ReactMarkdown from "react-markdown";

const errorMsg = "This field is required";

const getErrorText = (formik: any, name: string): any => {
    return getIn(formik.touched, name) && getIn(formik.errors, name) ? getIn(formik.errors, name) : null;
};

interface IQuestionProps {
    disabled?: boolean;
    formik: {
        handleChange: (e: any) => void;
        handleBlur: (e: any) => void;
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void>;
        setFieldError: (field: string, value: string) => void;
        values: { [name: string]: any };
    };
    tabIndex: number;
    vaccineName?: string;
    hideNote?: boolean
    toddler?: boolean
    adult?: boolean
    noMin?: boolean
    locationID?: string,
    clinicTypeID?: string
}

interface ITextInputQuestionProps extends IQuestionProps {
    name: string;
    label?: string;
    subLabel?: string;
    required?: boolean;
    width?: SemanticWIDTHS;
}
export function TextInputQuestion(props: ITextInputQuestionProps) {
    const error = getErrorText(props.formik, props.name);

    const handleChange = (e: any, data: InputOnChangeData) => {
        props.formik.setFieldValue(props.name, data.value);
    };

    return (
        <Form.Input
            key={props.name}
            aria-label={props.label}
            label={
                (props.label || props.subLabel) && (
                    <AppLabel
                        label={props.label}
                        subLabel={props.subLabel}
                        required={props.required}
                        className="field-label"
                    />
                )
            }
            name={props.name}
            onChange={handleChange}
            value={props.formik.values[props.name] || ""}
            disabled={props.disabled}
            error={error && { content: error }}
            width={props.width}
        />
    );
}

export function JurisdictionQuestion(props: IQuestionProps) {
    const name = "jurisdiction";

    const ctx = useContext(AppContext);
    const [options] = useState(ctx.config.getJurisdictionList());
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppDropdown
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel.replace("REPLACE_FIND_VACCINE_URL", ctx.config.findVaccine)}
            options={options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
            required={true}
            tabIndex={props.tabIndex}
        />
    );
}

export function VaccineDateQuestion(props: IQuestionProps) {
    const name = "vaccineDate";

    const value = props.formik.values[name];
    const vaccineName = props.formik.values.vaccineName;

    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    let label
    switch (props.formik.values.vaccineICName[0]) {
        case 'mmr':
            label = ctx.getLabel('mmrDateRecived');
            break;
        case 'polio':
            label = ctx.getLabel('polioDateRecived');
            break;
        default:
            label = questionConfig.label;
            break;
    }

    let error = getErrorText(props.formik, name);
    if (!error && value && vaccineName) {
        const vaccine = ctx.config.vaccines.find((x) => x.value === vaccineName);
        const approved = vaccine?.approved;
        if (approved) {
            const approvedDate = moment(approved);
            if (moment(value, "M/D/YYYY").isBefore(approvedDate)) {
                const date = moment(approved).format("MM/DD/YY");
                const vaccineName = vaccine[ctx.lang];
                error = ctx
                    .getLabel("vaccineDateError")
                    .replace("REPLACE_DATE", date)
                    .replace("REPLACE_VACCINE_NAME", vaccineName);
            }
        }
    }

    return (
        <AppDate
            name={name}
            label={label}
            subLabel={questionConfig.subLabel}
            disabled={props.disabled}
            value={value}
            onChanged={(value) => props.formik.setFieldValue(name, value)}
            error={error}
            required={vaccineName === "dont-remember"? false : true}
            tabIndex={props.tabIndex}
            locale={ctx.lang}
            noMin={props.noMin}
        />
    );
}

export function VaccineLatestDateQuestion(props: IQuestionProps) {
    const name = "vaccineLatestDate";

    const value = props.formik.values[name];
    const vaccineName = props.formik.values.vaccineName;

    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    let error = getErrorText(props.formik, name);
    if (!error && value && vaccineName) {
        const vaccine = ctx.config.vaccines.find((x) => x.value === vaccineName);
        const approved = vaccine?.approved;
        if (approved) {
            const approvedDate = moment(approved);
            if (moment(value, "M/D/YYYY").isBefore(approvedDate)) {
                const date = moment(approved).format("MM/DD/YY");
                const vaccineName = vaccine[ctx.lang];
                error = ctx
                    .getLabel("vaccineDateError")
                    .replace("REPLACE_DATE", date)
                    .replace("REPLACE_VACCINE_NAME", vaccineName);
            }
        }
    }

    return (
        <AppDate
            name={name}
            label={questionConfig.label}
            subLabel={questionConfig.subLabel}
            disabled={props.disabled}
            value={value}
            onChanged={(value) => props.formik.setFieldValue(name, value)}
            error={error}
            required={true}
            tabIndex={props.tabIndex}
            locale={ctx.lang}
        />
    );
}

export function IsRepresentativeQuestion(props: IQuestionProps) {
    const name = "isRepresentative";

    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function VaccineReceivedQuestion(props: IQuestionProps) {
    const name = "vaccineReceived";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel}
            required={false}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function PediatricICQuestion(props: IQuestionProps) {
    const name = "pediatricICQuestion";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel}
            required={false}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function AdditionalDoseScheduledQuestion(props: IQuestionProps) {
    const name = "doseType";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options.filter((option) => {
                if (option.value === "3") return false
                return true
            })}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function VaccineNameQuestion(props: IQuestionProps) {
    const name = "vaccineName";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={ctx.config.vaccines.map((x: any) => ({ text: x[ctx.lang], value: x.value }))}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function VaccineNameQuestionBooster(props: IQuestionProps) {
    const name = "vaccineName";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    let vaccines = ctx.config.vaccines.slice(2);

    const doseType = props.formik.values.doseType;

    return (
        <>
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={
                doseType !== "2" ?
                    vaccines.map((x: any) => ({ text: x[ctx.lang], value: x.value })) :
                    vaccines.filter((x: any) => x.value === 'Novavax COVID-19 Vaccine' || x.value === 'dont-remember')
                            .map((x: any) => ({ text: x[ctx.lang], value: x.value }))
            }
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
        {doseType==="2" && 
            props.formik.values.vaccineICName === "COVID" && 
            props.formik.values.covidAge === "adult" && 
            props.formik.values.vaccineName === "dont-remember" &&
            <Message
            icon="info circle"
            size={"tiny"}
            content={<ReactMarkdown linkTarget="_blank">{ctx.getLabel("dontKnow")}</ReactMarkdown>}
        />
            }
    </>
    );
}

export function BoosterNumber(props: IQuestionProps) {
    const name = "boosterNumber";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function VaccineNameQuestionPediatric(props: IQuestionProps) {
    const name = "vaccineName";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={ctx.config.vaccines.filter(vac => vac.value.indexOf('Peds') >= 0 || vac.value.indexOf('remember') >= 0).map(vac => ({
                text: vac[ctx.lang],
                value: vac.value
            }))}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function PediatricQuestionDoseType(props: IQuestionProps) {
    const name = "pediatricQuestionDoseType";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel}
            required={false}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options.filter(option => !props.adult && option.value !== "additional")}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function SingleDoseVaccineQuestion(props: IQuestionProps) {
    const name = "singleDoseConfirm";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    const opts = questionConfig.options.map((opt) => {
        opt.text = opt.text.replace("REPLACE_VACCINE_NAME", props.vaccineName);
        return opt;
    });
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={opts}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function ApptTypeQuestion(props: IQuestionProps) {
    const name = "apptType";

    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    //Filter if it's from 3rd parTY tp.
    if (props.clinicTypeID === 'TP') {
        questionConfig.options = questionConfig.options.filter(data => data.value !== 'future')
    }

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}
export type LanguageId = "en" | "es";
const getQuestionsfromSF = async (apiUrl: string, locationId: string) => {
    let vaccineICName = {
        en: {
            label: "Select the type of appointment you want to schedule for:",
            subLabel : "General Routine; Multiple Routine",
        },
        es: {
            label: "Seleccione el tipo de cita que quiere programar:",
        }
    }
    const options = await getVaccinationInfoByLocation(apiUrl, locationId);
    //@ts-ignore
    vaccineICName.options = options.vaccineCarriedList;
    return vaccineICName;
}
function getSFQuestionForLanguage(vaccineData: any, lang: LanguageId = "en") {
    const d = vaccineData
    const labels = d[lang];
    const options = d.options?.map((o: any) => ({ value: o.value, text: o[lang] }));
    return {
        label: labels.label,
        subLabel: labels.subLabel,
        subLabelLink: labels.subLabelLink,
        options: options,
    };
}
export interface IQuestionData {
    label: string;
    subLabel: string;
    subLabelLink: string;
    options: IOption[];
}
const getSFVaccinationInfo = async (apiUrl: string, locationID: string, language: LanguageId) => {
    const vaccineValuesfromSF = await getQuestionsfromSF(apiUrl, locationID)
    //console.log(`vaccineValuesfromSF: ${JSON.stringify(vaccineValuesfromSF)}`)
    const _processedData = getSFQuestionForLanguage(vaccineValuesfromSF, language);
    //console.log(`_processedData: ${JSON.stringify(_processedData)}`)
    return _processedData;
}

const questionSortOrder = (configData: any) => {
    if (configData) {
        return configData.options.sort((a: any, b: any) => a.text.localeCompare(b.text))
    }
}
export function VaccineICQuestion(props: IQuestionProps) {
    const name = "vaccineICName";
    const ctx = useContext(AppContext);
    const [configData, setconfigData] = useState<IQuestionData>(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.locationID && props.clinicTypeID !== 'TP') {
                    let configData = await getSFVaccinationInfo(ctx.apiUrl, props.locationID, ctx.lang);
                    questionSortOrder(configData)
                    setconfigData(configData);
                }
                else {
                    const questionConfig = ctx.getQuestion(name);
                    questionSortOrder(questionConfig)
                    setconfigData(questionConfig);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [props.locationID]);

    return (
        <div>
            {/* {configData ? <AppDropdown
                name={name}
                label={configData.label}
                note={configData.subLabel}
                required={true}
                value={props.formik.values[name]}
                error={getErrorText(props.formik, name)}
                onChanged={(val) => props.formik.setFieldValue(name, val)}
                options={configData.options}
                tabIndex={props.tabIndex}
                disabled={props.disabled}
            /> : (
                <p>{'Loading...'}</p>
            )} */}
            {configData ? <MultiCheckBox
                name={name}
                label={configData.label}
                note={configData.subLabel}
                required={true}
                value={props.formik.values[name]}
                error={getErrorText(props.formik, name)}
                onChanged={(val) => props.formik.setFieldValue(name, val)}
                options={configData.options}
                tabIndex={props.tabIndex}
                disabled={props.disabled}
            /> : (
                <p>{'Loading...'}</p>
            )}
        </div>
    );

}

export function CovidAge(props: IQuestionProps) {
    const name = "covidAge";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function PediatricVaccineQuestion(props: IQuestionProps) {
    const name = "pediatricVaccine";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function TestLabQuestion(props: IQuestionProps) {
    const name = "testLabGroupQuestion";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function ICQuestion(props: IQuestionProps) {
    const name = "questionIC";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={false}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function VaccineAgeQuestion(props: IQuestionProps) {
    const name = "vaccineAge";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function WhichDosePedsQuestion(props: IQuestionProps) {
    const name = "whichDose";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    console.log(`minor or toddler feild - ${JSON.stringify(questionConfig)}`)

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options.filter(option => {
                // if (props.toddler && Number.isNaN(parseInt(option.value))) return false
                // return true
                if (props.formik.values.covidAge === 'minor' &&
                    (option.value === "3" || option.value === "2")) return false
                return true
            })}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function GeneralAppointmentReason(props: IQuestionProps) {
    const name = "generalAppointmentReason";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppDropdown
            name={name}
            label={questionConfig.label}
            options={questionConfig.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
            required={true}
            tabIndex={props.tabIndex}
        />
    );
}

export function GeneralAppointmentText(props: IQuestionProps) {
    const name = "generalAppointmentText";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppTextbox
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val ? val.slice(0, 20) : '')}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function DobQuestion(props: IQuestionProps) {
    const name = "dobQuestion";

    const value = props.formik.values[name];

    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);


    return (
        <AppDate
            name={name}
            label={questionConfig.label}
            subLabel={questionConfig.subLabel}
            disabled={props.disabled}
            value={value}
            onChanged={(value) => props.formik.setFieldValue(name, value)}
            required={true}
            tabIndex={props.tabIndex}
            locale={ctx.lang}
            noMin={true}
            error={getErrorText(props.formik, name)}
        />
    );
}

export function ReceivedMmr(props: IQuestionProps) {
    const name = "receivedMmr";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function AtRiskMmr(props: IQuestionProps) {
    const name = "atRiskMmr";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function MmrDose(props: IQuestionProps) {
    const name = "mmrDose";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function MmrVaccine(props: IQuestionProps) {
    const name = "mmrVaccine";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function ReceivedPolio(props: IQuestionProps) {
    const name = "receivedPolio";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function AtRiskPolio(props: IQuestionProps) {
    const name = "atRiskPolio";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function AtRiskGeneral(props: IQuestionProps) {
    const name = "atRiskGeneral";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={false}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function PolioDose(props: IQuestionProps) {
    const name = "polioDose";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function PolioVaccine(props: IQuestionProps) {
    const name = "polioVaccine";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function WhichRoutineDose(props: IQuestionProps) {
    const name = "whichRoutineDose";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
   
    return (
        <WhichRoutineDoseRadio
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
            vaccine={props.formik.values.vaccineICName}
        />
    );
}

export function MpoxDose(props: IQuestionProps) {
    const name = "mpoxDose";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function WhichMpoxVaccine(props: IQuestionProps) {
    const name = "whichMpoxVaccine";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function CoadminFlue(props: IQuestionProps) {
    const name = "coadminFlu";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={(!props.hideNote ? questionConfig.subLabel : null)}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

import React from "react";
import ConfigurationService, { IJurisdiction } from "../services/ConfigurationService";
import { IQuestionData, LabelId, LanguageId, QuestionId } from "../shared/multi-language";

export type JurisdictionLabelId = "languageTooltip" |
"guestOpening" |
"guestFormPrompt" |
"boostFormPrompt" |
"boostFormPromptI" |
"boostFormPromptII" |
"additionalDoseFormPrompt" |
"opening" |
"welcome" |
"attestation" |
"attestationAdditionalDose" |
"noappointment";

export interface IAppContext {
    config: ConfigurationService;
    jurisdiction: IJurisdiction;
    isvamsrefresh:string | null;
    lang: LanguageId;
    clinicID: string | null;
    locationID: string | null;
    availVaccines: string[];
    clinicType: string;
    apiUrl:string| null;
    getQuestion: (id: QuestionId) => IQuestionData;
    getLabel: (id: LabelId) => string;
    getJurisdictionLabel: (id: JurisdictionLabelId) => string;
    bannerMessage: {
        en: string
        es: string
    }
}

const defaultContext: IAppContext = {
    config: null as any,
    jurisdiction: null as any,
    isvamsrefresh:null as any,
    lang: null as any,
    getQuestion: null as any,
    getLabel: null as any,
    getJurisdictionLabel: null as any,
    bannerMessage: {es: "", en: ""} as any,
    clinicID: null,
    locationID: null,
    availVaccines: [],
    clinicType: "",
    apiUrl:""
};
const AppContext = React.createContext(defaultContext);

export default AppContext;

import axios from 'axios'
export const getVaccinationInfoByLocation = async (apiUrl:string, locationId: string) => {

  try {
    //backend/template.yaml /guest-api/sfvaccine/{locatinID}
    const data = await fetch(`${apiUrl}/guest-api/sfvaccine/${locationId}`)
      .then(res => res.json());
    console.log(`json data :${JSON.stringify(data)}`)
    return data;

  } catch (err) {
    console.log(err)
  }
}

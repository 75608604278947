import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import AppContext from "../contexts/AppContext";
import {
    JurisdictionQuestion,
    TestLabQuestion,
} from "./base/Questions";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { UrlHelper } from "../shared/url.helper";

interface FormData {
    jurisdiction: string;
    testLabGroupQuestion: string,
    attestationChecked: boolean;
}

export function TestLab() {
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);

    const [message, setMessage] = useState<{
        header: string;
        body: string;
        confirmation?: string;
        showOverwrite?: boolean;
        error?: boolean;
        success?: boolean;
        warning?: boolean;
    }>(null);

    const ctx = useContext(AppContext);

    const onSubmit = async (values: FormData) => {
        try {
            setDisabled(true);
            setMessage(null);

            const url = new URL(ctx.config.guestUrl);
            let doseType = "";

            switch (values.testLabGroupQuestion) {
                case "DLT":
                    {
                        doseType = "Diagnostic Lab Test- PCR (Molecular-PCR SARS-CoV2)";
                        break
                    }
                case "RDT":
                    {
                        doseType = "Rapid Diagnostic Test (IDNow Rapid COVID-19 PCR)";
                        break
                    }
                case "RAT":
                    {

                        doseType = "Rapid Antigen Test (BinaxNOW Rapid COVID-19 Antigen)";
                        break
                    }
                case "SST":
                    {
                        doseType = "COVID-19 Antibody (SST)";
                        break
                    }
            }

            url.searchParams.append("doseType", doseType);
            url.searchParams.append("vaccineType", "COVID-19 testing");
            url.searchParams.append("jurisdiction", values.jurisdiction);
            url.searchParams.append("language", ctx.lang === "en" ? "en_US" : ctx.lang);
            debugger
            window.location.href = url.toString();
        } catch (error) {
            console.log(error);
            // @ts-ignore
            setMessage({ error: true, header: "Error", body: error.message });
            setDisabled(false);
        }
    };

    const defaultValues = (): FormData => {
        return {
            jurisdiction: ctx.jurisdiction.id,
            testLabGroupQuestion: undefined,
            attestationChecked: false,
        };
    };

    const formik = useFormik({
        initialValues: defaultValues(),
        validationSchema: Yup.object({
            jurisdiction: Yup.string().required(ctx.getLabel("requiredFieldError")),
            testLabGroupQuestion: Yup.string().required(ctx.getLabel("requiredFieldError")),
            attestationChecked: Yup.boolean().required(ctx.getLabel("requiredFieldError")),
        }),
        onSubmit: onSubmit,
    });

    const boosterNote = () => (
        <div>
            <ul>
                <li> <ReactMarkdown linkTarget="_blank">{ctx.getLabel("testingInformational")}</ReactMarkdown></li>
                <li><ReactMarkdown linkTarget="_blank">{ctx.getLabel("requiredNote")}</ReactMarkdown></li>
            </ul>
        </div>
    );

    const attestationText = () => {
        return ctx.getLabel("attestation");
    }

    return (
        <Grid container>
            <Grid.Row>
                <Grid.Column>
                    <Header as="h1">{ctx.getJurisdictionLabel("welcome")}</Header>
                    <Message icon="info circle" content={boosterNote()} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Form success className="eligibility-form" onSubmit={formik.handleSubmit}>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment padded>
                                        <Grid columns={1}>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <JurisdictionQuestion
                                                        formik={formik as any}
                                                        disabled={disabled}
                                                        tabIndex={1}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <TestLabQuestion
                                                        formik={formik as any}
                                                        disabled={disabled}
                                                        tabIndex={2}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>

                                            {(
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Form.Checkbox
                                                            label={attestationText()}
                                                            aria-label={attestationText()}
                                                            disabled={disabled}
                                                            checked={formik.values.attestationChecked}
                                                            onClick={async () => {
                                                                if (!formik.isValid) {
                                                                    const errorFields = {} as any;
                                                                    Object.keys(formik.errors).forEach(
                                                                        (key) => (errorFields[key] = true)
                                                                    );
                                                                    formik.setTouched(errorFields, true);
                                                                }
                                                                formik.setFieldValue(
                                                                    "attestationChecked",
                                                                    !formik.values.attestationChecked
                                                                );
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}

                                            {message && (
                                                <Message
                                                    visible={message ? true : false}
                                                    error={message?.error}
                                                    success={message?.success}
                                                >
                                                    <Message.Header>{message?.header}</Message.Header>
                                                    {message?.body && <p>{message.body}</p>}
                                                </Message>
                                            )}
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign={"right"}>
                                    <Button primary disabled={!formik.values.attestationChecked} type="submit">
                                        {ctx.getLabel("next")}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

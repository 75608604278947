import { useFormik } from "formik";
import React, { useContext } from "react";
import { Button, Grid, Header, Message, Segment } from "semantic-ui-react";
import { SingleDoseVaccineQuestion } from "./base/Questions";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import AppContext from "../contexts/AppContext";
import { UrlHelper } from "../shared/url.helper";

interface FormData {
    singleDoseConfirm: "yes" | "no" | "";
}
export function SingleDoseVaccine() {
    const history = useHistory();
    const ctx = useContext(AppContext);

    const vaccineName = UrlHelper.get("vaccinename");
    const vaccine: any = ctx.config.vaccines.find((x) => x.value === vaccineName);

    const singleDoseLabel = ctx.getLabel("singleDoseLabel").replace("REPLACE_VACCINE_NAME", vaccine[ctx.lang]);
    const singleDoseSublabel = ctx.getLabel("singleDoseSublabel").replace("REPLACE_VACCINE_NAME", vaccine[ctx.lang]);

    const onSubmit = async (values: FormData) => {
        try {
            if (values.singleDoseConfirm === "yes") {
                history.push(`/vaccinationComplete${window.location.search}`);
            } else {
                history.push(`/guest${window.location.search}`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const defaultValues = (): FormData => {
        return {
            singleDoseConfirm: "",
        };
    };

    const formik = useFormik({
        initialValues: defaultValues(),
        validationSchema: Yup.object({
            singleDoseConfirm: Yup.string().required(ctx.getLabel("requiredFieldError")),
        }),
        onSubmit: onSubmit,
    });

    return (
        <Grid container>
            <Grid.Row>
                <section>
                    <Header as="h2">{ctx.getJurisdictionLabel("welcome")}</Header>
                    <p>{ctx.getJurisdictionLabel("guestFormPrompt")}</p>
                </section>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment padded className="form ui">
                        <Header as="h3">{vaccine[ctx.lang]}</Header>
                        <p>{singleDoseLabel}</p>
                        <Message icon="info circle" content={singleDoseSublabel} />
                        <SingleDoseVaccineQuestion
                            vaccineName={vaccine[ctx.lang]}
                            formik={formik as any}
                            tabIndex={0}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <span className="spacer"></span>
                <div>
                    <Button basic onClick={() => history.push(`/guest${window.location.search}`)}>
                        {ctx.getLabel("cancel")}
                    </Button>
                    <Button primary onClick={() => formik.handleSubmit()}>
                        {ctx.getLabel("confirm")}
                    </Button>
                </div>
            </Grid.Row>
        </Grid>
    );
}

import { IOption } from "../components/base/AppFormInputs";
import { IMultiLangLabel, LanguageId } from "../shared/multi-language";
import { getLogger, Logger } from "./LoggingService";
import { sortBy } from "lodash"

interface IVaccine extends IOption {
    approved?: string;
    en: string;
    es: string;
}

export interface IJurisdiction {
    id: string;

    // override fields
    logo: string;
    opening?: IMultiLangLabel;
    welcome?: IMultiLangLabel;
    guestFormPrompt?: IMultiLangLabel;
    boostFormPrompt?: IMultiLangLabel;
    boostFormPromptI?: IMultiLangLabel;
    boostFormPromptII?: IMultiLangLabel;
    additionalDoseFormPrompt?: IMultiLangLabel;
    guestOpening?: IMultiLangLabel;
    languageTooltip?: IMultiLangLabel;
    languageEnabled: boolean;
    enableAuthRep: boolean;
    attestation: IMultiLangLabel;
    attestationAdditionalDose: IMultiLangLabel;
    noappointment: IMultiLangLabel;
    name: string;
}

export default class ConfigurationService {
    private logger: Logger;

    // These properties are assigned from config.json
    environment: string;
    region: string;
    buildTime: number;
    version: string;
    apiUrl: string;

    defaultTitle?: string;
    loginUrl: string;
    guestUrl: string;
    guestUrlvamsrefresh:string
    manageApptsUrl: string;
    findVaccine: string;

    vaccines: IVaccine[];

    // Disabled captcha used for local development
    captchaEnabled: string;

    default: IJurisdiction;
    jurisdictions: {
        [name: string]: IJurisdiction;
    };

    constructor() {
        this.logger = getLogger("ConfigurationService");
    }

    public async load() {
        const response = await fetch("config.json");
        const cfg = await response.json();
        Object.assign(this, cfg);
        this.logger.debug("Config Loaded");
    }

    getJurisdiction(name: string): IJurisdiction {
        if (!this.jurisdictions[name]) {
            return Object.assign({ ...this.default }, { id: name });
        }
        const result = Object.assign({ ...this.default }, this.jurisdictions[name], { id: name });
        console.log(result);
        return result;
    }

    getJurisdictionList(): IOption[] {
        const output = []

        for (const jurisdiction in this.jurisdictions) {
            if (Object.prototype.hasOwnProperty.call(this.jurisdictions, jurisdiction)) {
                output.push({
                    text: this.jurisdictions[jurisdiction].name,
                    value: jurisdiction
                })
            }
        }

        return sortBy(output, (o) => o.text)
    }
}

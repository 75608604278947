import React, { useContext, useState } from "react";
import { Accordion, Divider, Grid, Icon, Transition } from "semantic-ui-react";
import AppContext from "../../../contexts/AppContext";
import "./Footer.css";

const supportLinks = [
    {
        en: "Visit CDC-INFO",
        es: "Visite CDC-INFO",
        href: "https://www.cdc.gov/cdc-info/index.html",
        icon: "computer",
    },
    {
        en: "Call 800-232-4636",
        es: "Llame al 800-232-4636",
        href: "tel:800-232-4636",
        icon: "phone",
    },
    {
        en: "Email CDC-INFO",
        es: "Envíe un email a CDC-INFO",
        href: "https://www.cdc.gov/cdc-info/index.html",
        icon: "mail",
    },
];
const infoLinks = [
    [
        {
            en: "About CDC",
            es: "Sobre los CDC",
            href: "https://www.cdc.gov/about/default.htm",
        },
        {
            en: "Jobs",
            es: "Empleos",
            href: "https://jobs.cdc.gov/",
        },
        {
            en: "Funding",
            es: "Financiamiento",
            href: "https://www.cdc.gov/funding/",
        },
        {
            en: "Policies",
            es: "Políticas",
            href: "https://www.cdc.gov/Other/policies.html",
        },
    ],
    [
        {
            en: "File Viewers & Players",
            es: "Visores y reproductores de archivos",
            href: "https://www.cdc.gov/other/plugins/index.html",
        },
        {
            en: "Privacy",
            es: "Privacidad",
            href: "https://www.cdc.gov/other/privacy.html",
        },
        {
            en: "FOIA",
            es: "FOIA",
            href: "https://www.cdc.gov/od/foia/",
        },
        {
            en: "No Fear Act",
            es: "Ley No FEAR",
            href: "https://www.cdc.gov/eeo/nofearact/index.htm",
        },
    ],
    [
        {
            en: "OIG",
            es: "OIG",
            href: "https://oig.hhs.gov/",
        },
        {
            en: "Nondiscrimination",
            es: "No discriminación",
            href: "https://www.cdc.gov/other/nondiscrimination.html",
        },
        {
            en: "Accessibility",
            es: "Accesibilidad",
            href: "https://www.cdc.gov/contact/accessibility.html",
        },
        {
            en: "CDC Information Index",
            es: "Índice de información de los CDC",
            href: "https://www.cdc.gov/az/a.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Faz%2Findex.html",
        },
    ],
];
const bottomLinks = [
    {
        en: "U.S. Department of Health & Human Services",
        es: "Departamento de Salud y Servicios Humanos de los EE. UU.",
        href: "https://www.hhs.gov/",
    },
    {
        en: "USA.gov",
        es: "USA.gov",
        href: "https://www.usa.gov/",
    },
    {
        en: "CDC Website Exit Disclaimer",
        es: "Descargo de responsabilidad de salida del sitio web de los CDC",
        href: "https://www.cdc.gov/Other/disclaimer.html",
    },
];

export function Footer() {
    const [activeIndex, setIndex] = useState(0);
    const ctx = useContext(AppContext);

    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setIndex(newIndex);
    };

    const generateInfoLinks = () =>
        infoLinks.map((group, i) => (
            <section key={`info-link-group-${i}`} className="info-link-group">
                {group.map((link, i2) => (
                    <p key={`info-link-${i2}`}>
                        <a rel="noopener noreferrer" target="_blank" href={link.href}>
                            {link[ctx.lang]}
                        </a>
                    </p>
                ))}
            </section>
        ));
    const generateSupportLinks = () =>
        supportLinks.map((link, i) => {
            if (!link) return <p key={`support-link-${i}`}>&nbsp;</p>;
            return (
                <p className="support-link" key={`support-link-${i}`}>
                    {link.href && (
                        <a rel="noopener noreferrer" target="_blank" href={link.href}>
                            <Icon name={link.icon as any} />
                            &nbsp;&nbsp;&nbsp;
                            {link[ctx.lang]}
                        </a>
                    )}
                    {!link.href && (
                        <>
                            <Icon name={link.icon as any} />
                            &nbsp;&nbsp;&nbsp;
                            {link[ctx.lang]}
                        </>
                    )}
                </p>
            );
        });
    const generateBottomLinks = (textAlign = "center") =>
        bottomLinks.map((link, i) => {
            return (
                <Grid.Column key={`bottom-link-${i}`} textAlign={textAlign as any}>
                    <a rel="noopener noreferrer" href={link.href} target="_blank">
                        {link[ctx.lang]}
                    </a>
                </Grid.Column>
            );
        });

    return (
        <footer>
            <div className="upper-footer">
                <Grid container columns={1}>
                    <Grid.Row verticalAlign="top">
                        <Grid.Column only="computer">
                            <div className="footer-link-container">
                                <div>
                                    <h2 className="section-title">{ctx.getLabel("footerSupportLabel")}</h2>
                                    <section className="support-links">{generateSupportLinks()}</section>
                                </div>
                                <div>
                                    <h2 className="section-title">{ctx.getLabel("footerInfoLabel")}</h2>
                                    <div className="info-link-container">{generateInfoLinks()}</div>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column only="mobile tablet mobile">
                            <Accordion>
                                <Accordion.Title
                                    className="section-title"
                                    active={activeIndex === 0}
                                    index={0}
                                    onClick={handleClick}
                                    content={ctx.getLabel("footerSupportLabel")}
                                />
                                <Accordion.Content
                                    className="pl-2"
                                    content={generateSupportLinks()}
                                    active={activeIndex === 0}
                                />
                                <Divider inverted clearing />
                                <Accordion.Title
                                    className="section-title"
                                    active={activeIndex === 1}
                                    index={1}
                                    onClick={handleClick}
                                    content={ctx.getLabel("footerInfoLabel")}
                                />
                                <Accordion.Content
                                    className="pl-2"
                                    content={generateInfoLinks()}
                                    active={activeIndex === 1}
                                />
                                <Divider inverted clearing />
                            </Accordion>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <div className="lower-footer">
                <Grid container>
                    <Grid.Row columns={3} only="computer" centered verticalAlign="middle">
                        {generateBottomLinks()}
                    </Grid.Row>
                    <Grid.Row columns={1} only="mobile tablet mobile" verticalAlign="middle">
                        {generateBottomLinks("left")}
                    </Grid.Row>
                </Grid>
            </div>
        </footer>
    );
}

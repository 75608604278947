import React, { Component } from "react";
import AppContext, { IAppContext, JurisdictionLabelId } from "./AppContext";
import ConfigurationService from "../services/ConfigurationService";
import { getLogger } from "../services/LoggingService";
import qs from "qs";
import { Grid, Message } from "semantic-ui-react";
import { GetLabelForLanguage, getQuestionForLanguage, LabelId, LanguageId, QuestionId } from "../shared/multi-language";

interface IState {
    status: "Loading" | "Ready";
    error: string;
    context: IAppContext;
}
export class AppContextProvider extends Component<{}, IState> {
    logger = getLogger("AppContextProvider");
    cfg: ConfigurationService;

    constructor(props: any) {
        super(props);

        this.cfg = new ConfigurationService();

        this.state = {
            status: "Loading",
            error: "",
            context: {
                config: null,
                jurisdiction: null,
                lang: null,
                isvamsrefresh:null,
                getQuestion: null,
                getLabel: null,
                getJurisdictionLabel: null,
                bannerMessage: null,
                clinicID: null,
                locationID: null,
                availVaccines: [],
                clinicType: null,
                apiUrl:null
            },
        };
    }

    async componentDidMount() {
        try {
            const qsParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            debugger
            const isvamsrefresh = (qsParams.vamsrefresh as string) || "";
            const jurisdiction = (qsParams.jurisdiction as string) || "";

            await this.cfg.load();

            const lang = (qsParams.lang as LanguageId) || "en";
            const jurisdictionConfig = this.cfg.getJurisdiction(jurisdiction);

            if (!jurisdictionConfig) {
                this.setState({
                    error: `Unable to locate configuration for ${jurisdiction}. Your jurisdiction is not eligible for VAMS vaccination.`,
                });
                return;
            }

            let banner: Banner = {
                en: '',
                es: ''
            }

            // Get banner message
            try {
                banner = await fetch(`${this.cfg.apiUrl}/guest-api/banner`)
                .then(res => res.json())
            } catch (bannerError) {
                this.logger.error(`could not get banner message`, bannerError)
            }

            // Get clinicID
            const clinicID = (qsParams.clinicID as string) || null;

            // Get locaitonID
            const locationID = (qsParams.locationID as string) || null;

            // Get clinicType
            const clinicType = (qsParams.clinicType as string) || '';

            // Get vaccines
            const vaccines = qsParams.vaccines as string || ''
            const availVaccines = vaccines?.split(',')
                .map(vaccine => vaccine.replaceAll('+', ' '))
                .filter(vaccine => vaccine.length)

            this.setState({
                ...this.state,
                status: "Ready",
                context: {
                    bannerMessage: banner,
                    config: this.cfg,
                    jurisdiction: jurisdictionConfig,
                    isvamsrefresh:isvamsrefresh,
                    lang: lang as any,
                    clinicID,
                    locationID,
                    availVaccines,
                    clinicType,
                    apiUrl: this.cfg.apiUrl,
                    getQuestion: (id: QuestionId) => getQuestionForLanguage(id, this.state.context.lang),
                    getLabel: (id: LabelId) => GetLabelForLanguage(id, this.state.context.lang),
                    getJurisdictionLabel: (id: JurisdictionLabelId) =>
                        this.state.context.jurisdiction[id][this.state.context.lang],
                },
            });

            document.title = this.cfg.defaultTitle;
        } catch (error) {
            this.logger.error(`Configuration Failed: ${error}`, error);
        }
    }

    render() {
        if (this.state.error) {
            return (
                    <Grid padded container>
                        <Grid.Row>
                            <Grid.Column>
                                <Message error>
                                    <Message.Header>Error</Message.Header>
                                    <p>{this.state.error}</p>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
            );
        }
        if (!this.state.context.config) {
            return (
                <div>
                    <p>{this.state.status}</p>
                </div>
            );
        }
        // @ts-ignore
        return <AppContext.Provider value={this.state.context}>{this.props.children}</AppContext.Provider>;
    }
}

interface Banner {
    en: string
    es: string
}
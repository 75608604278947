import React from "react"
import { Message } from "semantic-ui-react"
import moment from "moment"
import { FormikValues } from "formik";
import { IAppContext } from "../contexts/AppContext"

interface AgeRequirement {
  age: number
  unit: "m" | "y" | "d"
}
interface DoseRequirement {
  after: number
  unit: "m" | "y" | "d"
}

interface RequirementPath {
  [dose: number]: AgeRequirement | DoseRequirement,
}

interface RequirementTable {
  [vaccine: string]: {
    child: {
      typical: RequirementPath
      expedited: RequirementPath
      expeditedTod: RequirementPath
    }
    adult: {
      typical: RequirementPath
      expedited: RequirementPath
    }
  }
}

const mmrDayRequirement: RequirementTable = {
  "M-M-R II": {
    child: {
      typical: {
        1: {
          age: 1,
          unit: "y"
        },
        2: {
          age: 4,
          unit: "y"
        },
        3: {
          age: 4,
          unit: "y"
        }
      },
      expedited: {
        1: {
          age: 6,
          unit: "m"
        },
        2: {
          age: 1,
          unit: "y"
        }
      },
      expeditedTod: {
        1: {
          after: 0,
          unit: "d"
        },
        2: {
          after: 28,
          unit: "d"
        }
      }
    },
    adult: {
      typical: {
        1: {
          after: 0,
          unit: "d"
        },
        2: {
          after: 28,
          unit: "d"
        }
      },
      expedited: {
        1: {
          after: 0,
          unit: "d"
        },
        2: {
          after: 28,
          unit: "d"
        }
      }
    },
  },
  "Priorix": {
    child: {
      typical: {
        1: {
          age: 1,
          unit: "y"
        },
        2: {
          age: 4,
          unit: "y"
        }
      },
      expedited: {
        1: {
          age: 6,
          unit: "m"
        },
        2: {
          age: 1,
          unit: "y"
        }
      },
      expeditedTod: {
        1: {
          after: 0,
          unit: "d"
        },
        2: {
          after: 28,
          unit: "d"
        }
      }
    },
    adult: {
      typical: {
        1: {
          after: 0,
          unit: "d"
        },
        2: {
          after: 28,
          unit: "d"
        }
      },
      expedited: {
        1: {
          after: 0,
          unit: "d"
        },
        2: {
          after: 28,
          unit: "d"
        }
      }
    },
  },
  "ProQuad": {
    child: {
      typical: {
        1: {
          age: 1,
          unit: "y"
        },
        2: {
          age: 4,
          unit: "y"
        }
      },
      expedited: {},
      expeditedTod: {
        1: {
          after: 0,
          unit: "d"
        },
        2: {
          after: 3,
          unit: "m"
        }
      }
    },
    adult: {
      typical: {},
      expedited: {}
    },
  }
}

export const isInvalidMMR = (values: FormikValues): boolean => {
  const { mmrVaccine, dobQuestion, atRiskMmr, mmrDose } = values;
  const gracePeirodDays = 365

  // if (mmrVaccine === "" || dobQuestion === "" || atRiskMmr === "") {
  //   return
  // }

  const isUnder12 = moment(dobQuestion).isAfter(moment().subtract(12, 'y'))
  const isAbove1 = moment(dobQuestion).isBefore(moment().subtract(1, 'y'))
  const isExpedited = atRiskMmr === 'yes'
  let dose = 1
  if (mmrDose !== "") {
    dose = parseInt(mmrDose)
  }

  // if (isAbove1)

  if (!mmrDayRequirement[mmrVaccine]) {
    return false;
  }

  const rule = mmrDayRequirement[mmrVaccine][isUnder12 ? 'child' : 'adult'][isExpedited ? 'expedited' : 'typical'][dose]

  if (!rule) {
    return false;
  }

  // @ts-ignore
  const pastThis = rule.age ? moment().subtract(rule.age, rule.unit).add(gracePeirodDays, 'd') : moment().subtract(rule.after, rule.unit).add(gracePeirodDays, 'd')

  return moment(dobQuestion).isAfter(pastThis);
}

export default (formik: { values: FormikValues }, ctx: IAppContext) => (
  <Message
    icon="info circle"
    error
    // @ts-ignore
    visible={isInvalidMMR(formik.values)}
  >
    {ctx.getLabel("mmrLaterTime")}
  </Message>
);

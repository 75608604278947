import React from "react"
import { Message } from "semantic-ui-react"
import moment from "moment"
import { FormikValues } from "formik";
import { IAppContext } from "../contexts/AppContext"

export default (formik: { values: FormikValues }, ctx: IAppContext) => {
    const latestDate = formik.values.vaccineLatestDate ?
        formik.values.vaccineLatestDate :
        formik.values.vaccineDate
    console.log(formik.values.vaccineName)
    if (latestDate) {
        switch (formik.values.vaccineName) {
            case "Peds Pfizer-BioNTech COVID-19 Vaccine (ages 6 months - 11 years)":
                return (
                    <React.Fragment>
                        <Message
                            warning
                            visible={
                                formik.values.pediatricQuestionDoseType === "booster" &&
                                moment(latestDate).isAfter(moment().add(-150, "days"))
                            }
                        >
                            {ctx.getLabel("pediatricBoosterDateWarning")}
                        </Message>
                    </React.Fragment>
                )
            // case "Peds Moderna COVID-19 vaccine":
            //     return (
            //         <React.Fragment>
            //             <Message
            //                 warning
            //                 visible={
            //                     (
            //                         formik.values.whichDose === "3" &&
            //                         formik.values.pediatricICQuestion !== "yes"
            //                     )
            //                 }
            //             >
            //                 {ctx.getLabel("modernaThirdDoseWarning")}
            //             </Message>
            //         </React.Fragment>
            //     )
            case "Pfizer-BioNTech COVID-19 Vaccine":
            case "Moderna COVID-19 Vaccine":
                return (
                    <React.Fragment>
                        <Message
                            warning
                            visible={
                                (
                                    formik.values.doseType === "booster" &&
                                    moment(latestDate).isAfter(moment().add(-60, "days"))
                                )
                            }
                        >
                            {ctx.getLabel("boosterDateWarning")}
                        </Message>
                    </React.Fragment>
                )
            case "Novavax COVID-19 Vaccine":
                return (
                    <React.Fragment>
                        <Message
                            warning
                            visible={formik.values.doseType === "booster"}
                        >
                            {ctx.getLabel("boosterDateWarningNovavax")}
                        </Message>
                    </React.Fragment>
                )
            case "dont-remember":
                return (
                    <React.Fragment>
                        <Message
                            icon="info circle"
                            warning
                            visible={
                                formik.values.doseType === "booster" &&
                                moment(latestDate).isAfter(moment().add(-150, "days"))
                            }
                        >
                            {ctx.getLabel("boosterDateWarning")}
                        </Message>
                        <Message
                            icon="info circle"
                            warning
                            visible={
                                formik.values.doseType === "booster_2" &&
                                moment(latestDate).isAfter(moment().add(-60, "days"))
                            }
                        >
                            {ctx.getLabel("boosterDateWarning")}
                        </Message>
                    </React.Fragment>

                );
            case "janssen":
                return (
                    <Message
                        icon="info circle"
                        warning
                        visible={
                            formik.values.doseType === "booster" &&
                            moment(latestDate).isAfter(moment().add(-60, "days"))
                        }
                    >
                        {ctx.getLabel("boosterDateWarning")}
                    </Message>
                );

            default:
                return null;
        }
    }
};
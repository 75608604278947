import React, { useContext, useState } from "react";
import { Button, Grid, Icon, Image, Popup } from "semantic-ui-react";
import AppContext from "../../../contexts/AppContext";
import { UrlHelper } from "../../../shared/url.helper";
import ReactMarkdown from "react-markdown";
import "./Header.css";

export function Header() {
    const ctx = useContext(AppContext);


    const toggleLanguage = () => {
        const next = ctx.lang === "es" ? "en" : "es";
        const queryString = UrlHelper.upsertParams({
            lang: next,
            jurisdiction: ctx.jurisdiction.id,
        });
        window.location.href = `${window.location.origin}${window.location.pathname}${queryString}`;
    };

    const home =  `https://${new URL(ctx.config.guestUrl).host}/vaccineportal/s/`

    return (
        <React.Fragment>
            {
                ctx.bannerMessage.en ?
                <div id="maintenance-banner">
                    <ReactMarkdown linkTarget="_blank">{ctx.bannerMessage[ctx.lang]}</ReactMarkdown></div> :
                null
            }
            <div className="static-header">
                <Grid container columns={1}>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column>
                            <header className="responsive-header">
                                <div className="header-basic-branding" role="heading" aria-level={1}>
                                    <a href={home}>
                                        <Image src="logo-light.png" alt="VAMS Logo" />
                                    </a>
                                    <h1>
                                        Vaccine Administration <br />
                                        Management System
                                    </h1>

                                </div>
                                {ctx.jurisdiction.languageEnabled && (
                                    <div className="language-wrapper">
                                        <Popup
                                            content={ctx.getJurisdictionLabel("languageTooltip")}
                                            trigger={
                                                <Button
                                                    style={{
                                                        boxShadow: "",
                                                    }}
                                                    color="grey"
                                                    basic
                                                    icon
                                                    labelPosition="left"
                                                    className="langButton"
                                                    onClick={toggleLanguage}
                                                    inverted
                                                >
                                                    <Icon style={{ color: "lightgrey" }} name="globe" />
                                                    <span style={{ color: "lightgrey" }}>
                                                        {ctx.lang === "es" ? "English" : "Español"}
                                                    </span>
                                                </Button>
                                            }
                                        />
                                    </div>
                                )}
                            </header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </React.Fragment>
    );
}

interface Banner {
    en: string
    es: string
}